import * as React from "react"
import CheckoutForm from "../../components/forms/payments/checkoutForm"
import PaymentMethodForm from "../../components/forms/payments/paymentMethods"
import NewCardForm from "../../components/forms/payments/newCard"
import firebase from "firebase"
import config, { firebaseInit } from "../../config"
import { getMobilePayments } from "../../api/content"
import SideBarLayout from "../../components/SideBarLayout"
import SettingsMenu from "../../components/SettingMenu"

const profileUri = config.serverUri

const StatusSettingsPage = () => {
  const [error, setError] = React.useState("")
  const [paymentForm, setPaymentForm] = React.useState("checkout")
  const [userId, setUserId] = React.useState(null)

  const [loading, setLoading] = React.useState(true)
  const [unSuscribeForm, setUnSuscribeForm] = React.useState(false)
  const [paymentInfo, setPaymentInfo] = React.useState({
    paymentMethods: [],
  })
  const [token, setToken] = React.useState("")
  const [isSocioPremium, setIsSocioPremium] = React.useState(null)

  // const locationCurrent = [''];

  React.useEffect(() => {
    console.log("status useEffect call")
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      setUserId(user.uid)
      if (user) {
        user
          .getIdToken(true)
          .then(token => {
            setToken(token)

            getMobilePayments(token)
              .then(result => {
                setPaymentInfo(result)
                setLoading(false)
              })
              .catch(error => {
                setError(
                  "No se ha podido cargar tu perfil de pagos intenta nuevamente."
                )
                setLoading(false)
              })
            setLoading(false)
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        console.log("user is null")
      }
      fetch(`${profileUri}/users/?email=${user.email}`)
        .then(response => {
          response.json().then(data => {
            if (data[0]) {
              fetch(`${profileUri}/users-suscriptions/?userId=${data[0].id}`)
                .then(responseNest => {
                  responseNest.json().then(dataNest => {
                    dataNest[0] && dataNest[0].suscriptionId === "shift-socios"
                      ? setIsSocioPremium(true)
                      : setIsSocioPremium(false)
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    })
  }, [])

  const handleMethodChange = card => {
    console.log("selected method ")
    setPaymentForm("checkout")
    setPaymentInfo({ ...paymentInfo, defaultMethod: card })
  }
  const handleCardAdded = card => {
    setPaymentInfo({
      ...paymentInfo,
      paymentMethods: [card, ...paymentInfo.paymentMethods],
    })
    setPaymentForm("paymentMethods")
  }

  const handleMethodDelete = card => {
    let filteredMethods = paymentInfo.paymentMethods.filter(
      item => item.id !== card.id
    );
  
    // Si el método eliminado es el método predeterminado, establecerlo en null
    const newDefaultMethod = paymentInfo.defaultMethod && paymentInfo.defaultMethod.id === card.id
      ? null
      : paymentInfo.defaultMethod;
  
    console.log("new methods");
    setPaymentInfo({ ...paymentInfo, paymentMethods: filteredMethods, defaultMethod: newDefaultMethod });
    
    // Si se elimina el método predeterminado, vuelve a la pantalla de selección de métodos
    if (newDefaultMethod === null) {
      setPaymentForm("paymentMethods");
    }
  };
  

  return (
    <SideBarLayout>
      <SettingsMenu>
        <h1 className={`${unSuscribeForm ? "hidden" : "block"}`}>
          Estatus de la cuenta
        </h1>

        <div>
          {paymentForm === "checkout" ? (
            <CheckoutForm
              userId={userId}
              location={""}
              setUnSuscribeForm={setUnSuscribeForm}
              onEditMethodRequest={() => {
                setPaymentForm("paymentMethods")
              }}
              token={token}
              onSuccessSuscription={suscription => {
                setPaymentInfo({ ...paymentInfo, suscribed: true })
              }}
              onCancelSuscription={suscription => {
                setPaymentInfo({ ...paymentInfo, suscribed: false })
              }}
              paymentInfo={paymentInfo}
              isSocioPremium={isSocioPremium}
            ></CheckoutForm>
          ) : null}

          {paymentForm === "paymentMethods" ? (
            <PaymentMethodForm
              token={token}
              onDeleteMethod={handleMethodDelete}
              paymentMethods={paymentInfo.paymentMethods}
              selectedMethod={paymentInfo.defaultMethod}
              goBack={() => {
                setPaymentForm("checkout")
              }}
              onChangeMethod={handleMethodChange}
              onAddCardRequest={() => {
                setPaymentForm("newCard")
              }}
            ></PaymentMethodForm>
          ) : null}

          {paymentForm === "newCard" ? (
            <NewCardForm
              location={""}
              goBack={() => {
                setPaymentForm("paymentMethods")
              }}
              onCardAdded={handleCardAdded}
              token={token}
            ></NewCardForm>
          ) : null}
        </div>
      </SettingsMenu>
    </SideBarLayout>
  )
}

export default StatusSettingsPage
